import AnimatedModal from 'components/Molecules/AnimatedModal/AnimatedModal';
import { sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Menu, Segment } from 'semantic-ui-react';
import { ICategory } from 'shared/models/Category';
import { OrderSelection } from '../../components/OrderSelection';
import StoreListingHeader from '../../components/Organisms/PWA/Headers/StoreListingHeader';
import { ProductCard } from '../../components/ProductCard';
import { useDeliveryLocation } from '../../hooks/Location';
import { useStoreContext } from '../../hooks/useStoreContext';
import { useVenueContext } from '../../hooks/useVenueContext';
import useWindowHeight from '../../hooks/UseWindowHeight';
import { CreateCartDto, LineItemDto } from '../../services/CartService';
import { BasePath } from '../../shared/constants';
import { OrderType } from '../../shared/models/Cart';
import IProduct from '../../shared/models/Product';
import { formatEta, getCurrencyPrefix, getProductImage } from '../../shared/utils';
import { useAppContext, useEventContext } from '../App/AppContext';
import { BagIcon } from '../Cart';
import useCartContext from '../Cart/context';
import { useProductCategoryTabContext } from './product-context';
import {
  MenuHeader,
  MenuTitleContainer,
  NoProductsAvailableText,
  OrderChangeButtonWrapper,
  OrderMin,
  ProductListingContainer,
  StyledMenu,
} from './styled';

export interface ProductListingRouteProps {
  storeId: string;
  venueId: string;
}

interface ICartItemQuantity {
  id: string;
  productId: string;
  quantity: number;
}

const ProductListing = () => {
  const { storeId, venueId } = useParams<ProductListingRouteProps>();
  const history = useHistory();
  const { user, setShowLoader, setStore, orderType } = useAppContext();
  const {
    addItemToCart,
    updateLineItem,
    addLineItem,
    cart,
    numberOfCartItems,
    alcoholProductCount,
  } = useCartContext();
  const { venue } = useVenueContext({
    venueId,
  });
  const { store, storeEtas, categories, products, isLoading } = useStoreContext({
    storeId,
    fetchCategories: true,
    fetchLocations: true,
    fetchProducts: true,
    fetchEtas: true,
  });
  const { deliveryLocation, deliveryLocationId, deliveryLocationInput } = useDeliveryLocation();
  const { event } = useEventContext();

  const { activeCategory, setActiveCategory } = useProductCategoryTabContext();
  const [cartItems, setCartItems] = useState<ICartItemQuantity[]>([]);
  const [closedWarningModal, setClosedWarningModal] = useState(false);
  const clientHeight = useWindowHeight();

  const currencyPrefix = getCurrencyPrefix(venue?.currency);

  useEffect(() => {
    return () => setStore(undefined);
  }, []);

  // set order delivery option button states on page load
  useEffect(() => {
    if (!store) {
      return;
    }

    if (!store.active) {
      setClosedWarningModal(true);
    }
  }, [store]);

  useEffect(() => {
    if (!cart) {
      setCartItems([]);
      return;
    }
    const { lineItems } = cart;
    setCartItems(
      lineItems.map(item => ({
        quantity: item.quantity,
        id: item.id,
        productId: item.productId,
      })),
    );
  }, [cart]);

  const getfulfillmentText = () => {
    if (event) return;

    const etaMs =
      orderType === OrderType.DELIVERY ? storeEtas?.deliveryEtaMs : storeEtas?.pickupEtaMs;

    if (storeEtas?.valid && etaMs)
      return `ETA: ${formatEta(storeEtas.confidenceIntervalMs!, etaMs)}`;

    return 'ETA: N/A';
  };

  const handleItemClick = async (e: any, category: ICategory) => {
    const menuRef = document.getElementById('scrollable');
    if (menuRef) {
      menuRef.scrollTo(e.target.offsetLeft, 0);
    }
    setActiveCategory(category);
  };

  const goToProduct = (productId: string) => () => {
    if (store?.active) {
      history.push(`${history.location.pathname}/product/${productId}`);
    } else {
      toast('Store is currently closed');
    }
  };

  const handleNavigateBack = () => {
    history.replace(`/${BasePath.CONSUMER}/stores/${venue?.id}`, { return: true });
  };

  const editItemCart = async (
    quantity: number,
    product: IProduct,
    lineItemId: string | null,
    currentQuantity: number,
    hasSubProducts: boolean,
  ) => {
    if (!venue || !store) return;

    if (store && !store.active) {
      toast('Store is currently closed');
      return;
    }
    if (hasSubProducts && quantity > 0) {
      history.push(`${history.location.pathname}/product/${product.id}`);
      return;
    }
    if (product.alcohol) {
      if (alcoholProductCount + quantity > venue.alcoholLimit) {
        toast(`Limit of ${venue.alcoholLimit} alcoholic drinks per order`);
        return;
      } else {
        if (alcoholProductCount < alcoholProductCount + quantity) {
          history.push(`${history.location.pathname}/product/${product.id}`);
          return;
        }
      }
    }

    const lineItem: LineItemDto = {
      productId: product.id,
      quantity,
    };

    const isDelivery = orderType === OrderType.DELIVERY;

    const location = isDelivery && deliveryLocation;

    const payload: CreateCartDto = {
      storeId: store.id,
      orderType: orderType,
      lineItem,
      ...(user?.id && { userId: user.id }),
      ...(event?.id && { eventId: event.id }),
      ...(event?.scheduledTime && { scheduledTime: event?.scheduledTime }),
      ...(location && { location }),
      ...(deliveryLocationId && { deliveryLocationId }),
      ...(deliveryLocationInput && { deliveryLocationInput }),
    };

    setShowLoader(true);
    if (lineItemId) {
      if (quantity > 0) {
        await addLineItem(lineItem, currentQuantity, lineItemId, product.alcohol);
      } else {
        await updateLineItem(lineItemId, currentQuantity + quantity);
      }
      setShowLoader(false);
    } else {
      await addItemToCart(payload);
      setShowLoader(false);
    }
  };

  return (
    <ProductListingContainer clientHeight={clientHeight} cartCount={numberOfCartItems}>
      <StoreListingHeader entity={store} handleBack={handleNavigateBack}>
        <OrderSelection deepLink={true} entity={store} />
      </StoreListingHeader>
      {store && (
        <MenuTitleContainer>
          <>
            <MenuHeader>Menu Options</MenuHeader>
            {store.orderMinimum > 0 && (
              <OrderMin>
                {currencyPrefix}
                {store.orderMinimum} Minimum
              </OrderMin>
            )}
            {!store.active && <OrderMin>Currently Closed</OrderMin>}
            {store.active && <OrderMin>{getfulfillmentText()}</OrderMin>}
          </>
        </MenuTitleContainer>
      )}

      <StyledMenu pointing secondary id="scrollable" tabIndex="0" aria-label="product categories">
        {/* All category.active === true */}
        {categories?.map((category: ICategory) => (
          <Menu.Item
            as="button"
            key={category.id}
            name={category.name}
            active={activeCategory?.id === category.id}
            onClick={e => handleItemClick(e, category)}
          />
        ))}
      </StyledMenu>

      <main>
        <Segment clearing loading={isLoading} className="products">
          {!products.length && (
            <NoProductsAvailableText>No Products Available</NoProductsAvailableText>
          )}
          {products.map((product: IProduct) => {
            const lineItems = cartItems.filter(item => item.productId === product.id);
            const quantity = sumBy(lineItems, lineItem => lineItem.quantity);

            return (
              <ProductCard
                currencyPrefix={currencyPrefix}
                description={product.description}
                hasSubProducts={product.hasSubProducts}
                imageurl={getProductImage(product)}
                isFree={product.price <= 0}
                key={product.id}
                onCardClick={goToProduct(product.id)}
                price={Number(product.price).toFixed(2)}
                quantity={quantity}
                storeIsActive={store?.active ?? false}
                title={product.name}
                updateQuantity={(value: number) =>
                  editItemCart(
                    value,
                    product,
                    lineItems.length > 0 ? lineItems[0].id : null,
                    quantity,
                    product.hasSubProducts,
                  )
                }
                showProductImages={store?.showProductImages ?? false}
              />
            );
          })}
        </Segment>
      </main>
      {store?.active && numberOfCartItems > 0 && cart && (
        <footer>
          <Button fluid onClick={() => history.push(`/${BasePath.CONSUMER}/cart/${cart.id}`)}>
            <span>View Bag</span>
            <BagIcon />
          </Button>
        </footer>
      )}
      <AnimatedModal
        showModal={closedWarningModal}
        onCloseModal={() => setClosedWarningModal(false)}
      >
        <AnimatedModal.Title copy={`${store?.name.toUpperCase()} IS CLOSED`} />
        <AnimatedModal.Content>
          You may explore the menu offerings, but will be unable to add any items to your bag.
        </AnimatedModal.Content>
        <OrderChangeButtonWrapper>
          <AnimatedModal.Button
            copy="EXPLORE MENU ONLY"
            outline={true}
            onClick={() => setClosedWarningModal(false)}
          />
        </OrderChangeButtonWrapper>
      </AnimatedModal>
    </ProductListingContainer>
  );
};

export default ProductListing;
